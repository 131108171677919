import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

import * as fromTasks from "./task.reducer";
import * as fromProcesses from "./process.reducer";
import * as fromEndpoints from "./endpoint.reducer";
import * as fromIncidents from "./incident.reducer";
import * as fromTemplates from "./template.reducer";
import * as fromHelp from "./help.reducer";
import * as fromGroups from "./groups.reducer";
import * as fromAlerts from "./alert.reducer";

export interface UwlState {
  openTasks: fromTasks.TaskState;
  activeProcesses: fromProcesses.ProcessState;
  completedTasks: fromTasks.TaskState;
  completedProcesses: fromProcesses.ProcessState;
  incidents: fromIncidents.IncidentState;
  endpoints: fromEndpoints.EndpointState;
  templates: fromTemplates.TemplateState;
  help: fromHelp.HelpState;
  groups: fromGroups.GroupsState;
  alerts: fromAlerts.AlertState;
}

export const reducers: ActionReducerMap<UwlState> = {
  openTasks: fromTasks.taskOpenReducer,
  activeProcesses: fromProcesses.processActiveReducer,
  completedTasks: fromTasks.taskCompletedReducer,
  completedProcesses: fromProcesses.processCompletedReducer,
  incidents: fromIncidents.reducer,
  endpoints: fromEndpoints.reducer,
  templates: fromTemplates.reducer,
  help: fromHelp.reducer,
  groups: fromGroups.reducer,
  alerts: fromAlerts.reducer,
};

export const getUwlStore = createFeatureSelector<UwlState>("uwl");
